<template>
  <div class="container page-performance-details">
    <div class="card">
      <div class="ui active inverted dimmer page-loader" v-if="isLoading">
        <div class="ui medium text loader"><spinner :size="64" :loader="true" color /></div>
      </div>
      <section v-else>
        <h2 class="m-b-0">Banks comparison</h2>
        <v-client-table :data="banksPerformance" :columns="banksTableColumns" :options="banksTableOptions">
          <template v-slot:fullBankName="data">
            <div class="bank-logo m-b-5">
              <bank-logo class="logo" :bank-name="data.row.bankName" :alt="data.row.fullBankName" />
            </div>
            <b>{{ data.row.fullBankName }}</b>
          </template>
          <template v-slot:serviceFeeAmount="data">
            <template v-if="data.row.serviceFeeAmount === null">N/A</template>
            <template v-else><currency-symbol />{{ data.row.serviceFeeAmount | commarize }}<br />{{ data.row.serviceFeeFrequency | splitUpperCase }}</template>
          </template>
          <template v-slot:overdraftFacility="data">
            <icon :class="data.row.overdraftFacility ? 'text-primary' : 'text-danger'" :name="data.row.overdraftFacility ? 'check' : 'times'" />
          </template>
          <template v-slot:isInternationalPaymentsSupported="data">
            <icon :class="data.row.isInternationalPaymentsSupported ? 'text-primary' : 'text-danger'" :name="data.row.isInternationalPaymentsSupported ? 'check' : 'times'" />
          </template>
          <template v-slot:overdraftMaxAmount="data">
            <template v-if="data.row.overdraftMaxAmount === null">N/A</template>
            <template v-else><currency-symbol />{{ data.row.overdraftMaxAmount | commarize }}</template>
          </template>
          <template v-slot:overdraftEAR="data">
            <template v-if="data.row.overdraftEAR === null">N/A</template>
            <template v-else>{{ data.row.overdraftEAR }}%</template>
          </template>
          <template v-slot:hasAtmFees="data">
            <template v-if="data.row.hasAtmFees === null">N/A</template>
            <icon :class="data.row.hasAtmFees ? 'text-primary' : 'text-danger'" :name="data.row.hasAtmFees ? 'check' : 'times'" v-else />
          </template>
          <template v-slot:action="data">
            <div class="btn-holder">
              <a @click.prevent="seeFullInfoClickHandler(data.row)" class="btn btn-primary btn-sm" href>See Full Info</a>
            </div>
            <div class="btn-holder" v-if="bankMandateList && bankMandateList.length">
              <StartSwitchRevolut
                class="btn-sm"
                v-if="
                  data.row.bankName === 'revolutBankFreelancer' ||
                  data.row.bankName === 'revolutBankProfessional' ||
                  data.row.bankName === 'revolutBankStandard' ||
                  data.row.bankName === 'revolutBankStart'
                "
              />
              <router-link
                @click.native="sendStartSwitchToAmplitude(data.row)"
                class="btn btn-primary btn-sm"
                :to="{ name: 'banks-switch-account', params: { bankName: data.row.bankName } }"
                v-else
                >Start Switch</router-link
              >
            </div>
          </template>
        </v-client-table>
      </section>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { companyGetters } from '@/store/modules/company/routines'
import { bankMandateListRoutine, bankGetters } from '@/store/modules/bank/routines'
import BankLogo from '@/components/BankLogo'
import StartSwitchRevolut from '@/components/integrations/StartSwitchRevolut'
import { getBankComparison } from '@/api/openBankingOpenData'
import { amplitudeEvents } from '@/utils/constants'
import Vue from 'vue'
import { ClientTable } from 'vue-tables-2'
import vueTablesConf from '@/config/vue-tables'

Vue.use(ClientTable, vueTablesConf.options, vueTablesConf.useVuex, vueTablesConf.theme, vueTablesConf.template)

export default {
  name: 'PerformanceDetails',
  components: {
    BankLogo,
    StartSwitchRevolut
  },
  data() {
    return {
      isLoading: true,
      banksPerformance: null,
      banksTableColumns: [
        'fullBankName',
        'serviceFeeAmount',
        'overdraftFacility',
        'isInternationalPaymentsSupported',
        'overdraftMaxAmount',
        'overdraftEAR',
        'hasAtmFees',
        'action'
      ],
      banksTableOptions: {
        uniqueKey: 'bankName',
        skin: 'table table-v-align swoop-grid',
        filterable: false,
        headings: {
          fullBankName: 'Bank Name',
          serviceFeeAmount: 'A/c Fee',
          overdraftFacility: 'Overdraft Facility',
          isInternationalPaymentsSupported: 'International Payments',
          overdraftMaxAmount: 'Max Overdraft Limit',
          overdraftEAR: 'Overdraft EAR',
          hasAtmFees: 'ATM FEES'
        },
        sortable: [],
        perPage: 100
      },
      selectedBank: null
    }
  },
  computed: {
    ...mapGetters({
      companyId: companyGetters.COMPANY_ID,
      companyCurrent: companyGetters.COMPANY_CURRENT,
      bankMandateList: bankGetters.BANK_MANDATE_LIST
    })
  },
  methods: {
    ...mapActions({
      getBankMandateList: bankMandateListRoutine.TRIGGER
    }),
    async init() {
      this.isLoading = true
      await Promise.all([this.fillBanksPerformance(), this.getBankMandateList(this.companyId)])
      this.isLoading = false
    },
    fillBanksPerformance() {
      return getBankComparison()
        .then(res => {
          this.banksPerformance = res.data.map(bank => {
            const { bcas, fullBankName, bankName } = bank.bankComparisonModel
            const bca = bcas.find(bca => bca.isRepresentative) || bcas[0] || null
            const overdraft = bca ? bca.overdrafts.find(overdraft => overdraft.isRepresentative) || bca.overdrafts[0] || null : null
            const { isInternationalPaymentsSupported = null } = bca.features
            return {
              bankName,
              fullBankName,
              serviceFeeAmount: bca && bca.serviceFee ? bca.serviceFee.amount || null : null,
              serviceFeeFrequency: bca && bca.serviceFee ? bca.serviceFee.frequency || null : null,
              overdraftFacility: bca.overdrafts.length > 0,
              overdraftEAR: overdraft ? overdraft.earMin : null,
              overdraftMaxAmount: overdraft ? overdraft.valueMax : null,
              isInternationalPaymentsSupported,
              hasAtmFees: bca && typeof bca.hasAtmFees !== 'undefined' ? bca.hasAtmFees : null
            }
          })
        })
        .catch(() => {
          this.banksPerformance = []
        })
    },
    sendStartSwitchToAmplitude(bank) {
      /** Amplitude Analytics */
      this.$ma.trackEvent({ eventType: amplitudeEvents.savingCheckout.PERFORMANCE_SWITCH_CLICKED, eventProperties: { Bank: [this.bankName] } })
    },
    sendSeeFullInfoToAmplitude(bank) {
      /** Amplitude Analytics */
      this.$ma.addItem({
        'Bank Acc Product Details': 1
      })
      this.$ma.setUserProperties({
        'Bank Account Product': this.bankName
      })
      this.$ma.trackEvent({
        eventType: amplitudeEvents.savingCheckout.SEE_FULL_INFO,
        eventProperties: {
          'Bank Account Product': bank.fullBankName,
          'A/C Fee': `${bank.serviceFeeAmount} ${bank.serviceFeeFrequency}`,
          'Overdraft EAR': bank.overdraftEAR,
          'Max Overdraft Limit': bank.overdraftMaxAmount,
          'International payments support': bank.isInternationalPaymentsSupported,
          'Overdraft Facility': bank.overdraftFacility,
          'ATM Fees': bank.hasAtmFees
        }
      })
    },
    seeFullInfoClickHandler(bank) {
      this.sendSeeFullInfoToAmplitude(bank)
      this.$router.push({ name: 'bank-info', params: { bankName: bank.bankName } })
    }
  },
  mounted() {
    this.init()
  }
}
</script>

<style lang="scss" scoped>
@import '../../../assets/styles/swoop/variables';

.page-performance-details {
  flex-grow: 1;
}

.VueTables {
  max-width: 100%;
  /deep/ {
    td,
    th {
      text-align: center;
      &:first-child,
      &:last-child {
        text-align: left;
      }
    }
    .VuePagination,
    .VueTables__limit {
      display: none;
    }
    .bank-logo {
      .logo {
        display: block;
        max-width: 100px;
        max-height: 48px;
      }
    }
    .btn-holder:not(:last-child) {
      margin-bottom: 15px;
    }
    .btn {
      white-space: nowrap;
    }
  }
}
</style>
