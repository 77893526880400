<template>
  <img :src="src" :alt="alt + ' logo'" />
  <!-- v-if="logoFile" -->
</template>

<script>
export default {
  name: 'BankLogo',
  props: ['bankName', 'alt'],
  data() {
    return {
      logos: {
        adamAndCompanyBank: 'adamAndCompanyBank.png',
        alliedIrishBank: 'alliedIrishBank.png',
        'AIB (GB)': 'alliedIrishBank.png',
        'Anna Money': 'annaMoneylogo.svg',
        bankOfIreland: 'bankOfIreland.png',
        'Bank of Ireland (UK)': 'bankOfIreland.png',
        bankOfScotland: 'bankOfScotland.png',
        'Bank of Scotland': 'bankOfScotland.png',
        barclaysBank: 'barclaysBank.svg',
        Barclays: 'barclaysBank.svg',
        Cashplus: 'cashPlusLogo.jpeg',
        couttsBank: 'couttsBank.png',
        'Co-op': 'co-op.svg',
        'The Co-operative Bank': 'co-op.svg',
        danskeBank: 'danskeBank.svg',
        'Danske Bank': 'danskeBank.svg',
        esmeBank: 'esmeBank.svg',
        firstTrustBank: 'firstTrustBank.png',
        forgerock: 'forgerock.svg',
        halifaxBank: 'halifaxBank.svg',
        hsbcGroupBank: 'hsbcGroupBank.png',
        HSBC: 'hsbc-uk.svg',
        lloydsBank: 'lloydsBank.svg',
        Lloyds: 'lloydsBank.svg',
        Metro: 'Metro.svg',
        'Metro Bank': 'Metro.svg',
        natWestBank: 'natWestBank.jpg',
        NatWest: 'natWestBank.jpg',
        ozoneBank: 'ozone.png',
        revolutBankFreelancer: 'revolutBank.svg',
        revolutBankProfessional: 'revolutBank.svg',
        revolutBankStandard: 'revolutBank.svg',
        revolutBankStart: 'revolutBank.svg',
        Revolut: 'revolutBank.svg',
        royalBankOfScotland: 'royalBankOfScotland.svg',
        Rbs: 'royalBankOfScotland.svg',
        RBS: 'royalBankOfScotland.svg',
        TSB: 'tbs.png',
        santanderBank: 'santanderBank.svg',
        Santander: 'santanderBank.svg',
        STARLING: 'starling.svg',
        'STARLING BANK': 'starling.svg',
        ulsterBank: 'ulsterBank.png',
        'Ulster Bank (NI)': 'ulsterBank.png'
      }
    }
  },
  computed: {
    logoFile() {
      return this.logos[this.bankName] || null
    },
    src() {
      return `/img/banks-logos/${this.logoFile}`
    }
  }
}
</script>
